import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import axios from "axios";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Rules from "./pages/Rules";
import Staff from "./pages/Staff";
import Application from "./pages/Application";
import Login from "./pages/Login";
import Dashboard from "./pages/Dash"; // Dashboard page for admin/staff
import ManageUsers from "./pages/StaffManagement"; // User management page for admin
import ApplicationReview from "./pages/ApplicationReview";
import CreateUser from "./pages/CreateUser";

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // For loading state

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get("https://al.maximusrp.com:5000/api/user", {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }, // Send JWT token in the header
        });
        setUser(response.data);  // Set user state with the fetched data
      } catch (error) {
        setUser(null);  // If user is not authenticated
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token"); // Clear the JWT token
    setUser(null); // Clear user state
  };

  // Private Route Component to protect pages that require authentication
  const PrivateRoute = ({ element, roles }) => {
    if (loading) return <div>Loading...</div>; // Show loading state while fetching user data

    if (!user) return <Navigate to="/login" />; // Redirect to login if user is not authenticated
    if (roles && !roles.includes(user.role)) return <Navigate to="/" />; // Redirect if user does not have the required role

    return element;
  };

  return (
    <Router>
      <div>
        <Header user={user} onLogout={handleLogout} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/rules" element={<Rules />} />
          <Route path="/apply" element={<Application />} />
          {/* Protected Routes */}
          <Route path="/dashboard" element={<PrivateRoute element={<Dashboard onLogout={handleLogout} />} roles={['admin', 'staff']} />}/>
          <Route path="/staff" element={<Staff />}/>
          <Route path="/manage" element={<PrivateRoute element={<ManageUsers />} roles={['admin']} />} />
          <Route path="/create-user" element={<PrivateRoute element={<CreateUser />} roles={['admin']} />} />
          <Route path="/applications" element={<PrivateRoute element={<ApplicationReview />} roles={['admin', 'staff']} />} />
          {/* Login route */}
          <Route path="/login" element={<Login setUser={setUser} />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
