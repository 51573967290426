import React, { useState } from "react";
import axios from "axios";

const ApplicationForm = () => {
  const [formData, setFormData] = useState({
    discordId: "",
    email: "",
    age: "",
    question1: "",
    question2: "",
    question3: "",
    question4: "",
    question5: "",
    question6: "",
    question7: "",
    question8: "",
  });

  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");  // Reset message on submit
  
    try {
      const response = await axios.post("https://al.maximusrp.com:5000/api/applications", formData);
      console.log('Response:', response); // Log the response
      console.log(formData)
      if (response.status === 201) {
        setMessage("Application submitted successfully. Please check your email over the next 2-4 weeks.");
        setFormData({
          discordId: "",
          email: "",
          age: "",
          question1: "",
          question2: "",
          question3: "",
          question4: "",
          question5: "",
          question6: "",
          question7: "",
          question8: "",
        });
      }
    } catch (error) {
      console.error('Error response:', error.response || error);
      setMessage("Failed to submit the application. Please try again later.");
    }
  };

  return (
    <div className="container">
      <h1>Allowlist Application</h1>
      <form onSubmit={handleSubmit}>
        <h4>Input your Discord User ID (Ask a member of staff if unsure)</h4>
        <input
          type="text"
          name="discordId"
          placeholder="Your Discord User ID"
          value={formData.discordId}
          onChange={handleChange}
          required
        />
        <h4>Input your Email below.</h4>
        <input
          type="text"
          name="email"
          placeholder="john.doe@maximusrp.com"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <h4>Age</h4>
        <input
          type="text"
          name="age"
          placeholder="18"
          value={formData.age}
          onChange={handleChange}
          required
        />
        <h4>Copy and Paste a link to a short clip of your microphone (Must be within the last 30 days)</h4>
        <textarea
          name="question1"
          placeholder=""
          value={formData.question1}
          onChange={handleChange}
          required
        />
        <h4>First character's name</h4>
        <input
          type="text"
          name="question2"
          placeholder="John Doe"
          value={formData.question2}
          onChange={handleChange}
          required
        />
        <h4>Character Backstory</h4>
        <textarea
          name="question3"
          placeholder="John Doe is a hard working civil engineer from the streets of South LA, born and raised..."
          value={formData.question3}
          onChange={handleChange}
          required
        />
        <h4>Please tell us what is "NVL" and provide an example of NVL.</h4>
        <textarea
          name="question4"
          placeholder=""
          value={formData.question4}
          onChange={handleChange}
          required
        />
        <h4>Please tell us what is "RDM" and provide an example of RDM.</h4>
        <textarea
          name="question5"
          placeholder=""
          value={formData.question5}
          onChange={handleChange}
          required
        />
        <h4>A player goes OOC (Out of Character) mid scene, what do you do and tell us why you did this?</h4>
        <textarea
          name="question6"
          placeholder=""
          value={formData.question6}
          onChange={handleChange}
          required
        />
        <h4>Describe the best RP moment another player has enabled for you and why was this the best?</h4>
        <textarea
          name="question7"
          placeholder=""
          value={formData.question7}
          onChange={handleChange}
          required
        />
        <h4>Final Notes - Tell us something unique about yourself.</h4>
        <textarea
          name="question8"
          placeholder=""
          value={formData.question8}
          onChange={handleChange}
          required
        />
        <button type="submit">Submit</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default ApplicationForm;