import React, { useEffect, useState } from "react";
import axios from "axios";

const Dashboard = ({ onLogout }) => {
    const [stats, setStats] = useState({
      pending: 0,
      accepted: 0,
      denied: 0,
    });

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axios.get("https://al.maximusrp.com:5000/api/applications/stats", {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        setStats(response.data);
      } catch (error) {
        console.error("Error fetching application stats:", error);
      }
    };

    fetchStats();
  }, []);

  return (
    <div className="container">
      <h1>Dashboard</h1>
      <div style={styles.stats}>
        <div style={styles.statBox}>
          <h2>Pending Applications</h2>
          <p>{stats.pending}</p>
        </div>
        <div style={styles.statBox}>
          <h2>Accepted Applications</h2>
          <p>{stats.accepted}</p>
        </div>
        <div style={styles.statBox}>
          <h2>Denied Applications</h2>
          <p>{stats.denied}</p>
        </div>
      </div>
      <center>
      <button style={styles.logoutButton} onClick={onLogout}>Logout</button>
      </center>
    </div>
  );
};

const styles = {
  stats: {
    display: "flex",
    justifyContent: "space-around",
    marginBottom: "20px",
  },
  statBox: {
    padding: "10px 20px",
    borderRadius: "5px",
    textAlign: "center",
    width: "30%",
  },
  logoutButton: {
    backgroundColor: "#d9534f",
    color: "#fff",
    padding: "10px 15px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

export default Dashboard;
