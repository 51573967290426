import React from "react";
import { Link } from "react-router-dom";

const Header = ({ user, onLogout }) => {
  return (
    <>
      <header style={styles.header}>
        <h1 style={styles.title}>
          <Link to="/" style={styles.navLink}>
            Maximus Roleplay
          </Link>
        </h1>
        <nav>
          <ul style={styles.navList}>
            <li style={styles.navItem}>
              <Link to="/" style={styles.navLink}>Home</Link>
            </li>
            <li style={styles.navItem}>
              <a href="fivem://connect/https://cfx.maximusrp.com" style={styles.navLink} target="_blank" rel="noopener noreferrer">Connect</a>
            </li>
            <li style={styles.navItem}>
              <Link to="/rules" style={styles.navLink}>Rules</Link>
            </li>
            <li style={styles.navItem}>
              <Link to="/staff" style={styles.navLink}>Our Team</Link>
            </li>
            <li style={styles.navItem}>
              <Link to="/apply" style={styles.navLink}>Apply</Link>
            </li>
          </ul>
        </nav>
      </header>
      {user && (user.role === "admin" || user.role === "staff") && (
        <div style={styles.subHeader}>
          <ul style={styles.navList}>
            <li style={styles.navItem}>
              <Link to="/dashboard" style={styles.navLink}>Dashboard</Link>
            </li>
            <li style={styles.navItem}>
              <Link to="/applications" style={styles.navLink}>Applications</Link>
            </li>
            {user.role === "admin" && (
              <li style={styles.navItem}>
                <Link to="/manage" style={styles.navLink}>Manage Users</Link>
              </li>
            )}
            {user.role === "admin" && (
              <li style={styles.navItem}>
                <Link to="/create-user" style={styles.navLink}>Create User</Link>
              </li>
            )}
          </ul>
        </div>
      )}
    </>
  );
};

const styles = {
  header: {
    background: "#0051FF",
    color: "#fff",
    padding: "10px 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    margin: 0,
    fontSize: "24px",
  },
  navList: {
    listStyle: "none",
    display: "flex",
    margin: 0,
    padding: 0,
  },
  navItem: {
    marginLeft: "15px",
  },
  navLink: {
    color: "#fff",
    textDecoration: "none",
    fontSize: "18px",
  },
  subHeader: {
    background: "#0051FF",
    padding: "10px 20px",
    display: "flex",
    justifyContent: "center",
  },
};

export default Header;
