import React from "react";
import { FaDiscord } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa6";

const Footer = () => {
  return (
    <footer style={styles.footer}>
      <p>© {new Date().getFullYear()} Maximus Roleplay. All rights reserved.</p>
      <div style={styles.socialLinks}>
        <a href="https://x.com/maximusroleplay" target="_blank" rel="noopener noreferrer" style={styles.icon}>
          <FaXTwitter />
        </a>
        <a href="https://discord.gg/dbGgWERpy4" target="_blank" rel="noopener noreferrer" style={styles.icon}>
          <FaDiscord />
        </a>
        <a href="https://facebook.com/maximusroleplay" target="_blank" rel="noopener noreferrer" style={styles.icon}>
          <FaFacebookF />
        </a>
      </div>
    </footer>
  );
};

const styles = {
  footer: {
    background: "#0051ff",
    color: "#fff",
    textAlign: "center",
    padding: "10px 0",
    marginTop: "20px",
    position: "relative", // Allows positioning for social links
  },
  socialLinks: {
    marginTop: "10px", // Adjust spacing between text and icons
    display: "flex",
    justifyContent: "center", // Centers the icons horizontally
    gap: "20px", // Space between icons
  },
  icon: {
    color: "#fff",
    fontSize: "24px", // Icon size
    textDecoration: "none",
    transition: "color 0.3s",
  },
};

export default Footer;
