import React from "react";

const Rules = () => {
  return (
    <main style={styles.main}>
        <div className="container">
        <h2>General Rules</h2>
            <ul style = {styles.rulesList}>
                <li>R1 - Playing, streaming, watching or listening to any © content on MXRP is strictly prohibited, engaging in such an activity will warrant a ban in accordance to our PBS.</li>
                <br></br>
                <li>R2 - Engaging in any form of ERP (Erotic Roleplay) is strictly prohibited, this includes lewd or nude images shared of yourselves and/or your character.</li>
                <br></br>
                <li>R3 - Encouraging others to break any of these rules will warrant a ban for them and yourselves in line with PBS.</li>
                <br></br>
                <li>R4 - Avoid sensitive topics both within the server and in Discord. (These include but are not limited to - Terrorism RP, Suicide RP, Pandemics etc)</li>
                <br></br>
                <li>R5 - Roleplay should not stop unless directed to do so via a uniformed staff member.</li>
                <br></br>
                <li>R6 - Common Sense - Albeit not as common these days, please utilise Common Sense when it comes to situations within the Discord or Roleplay Server.</li>
                <br></br>
                <li>R7 - There is a zero tolerance policy surrounding the subjects of racism, homophobia, discrimination, sexism etc.</li>
                <br></br>
                <li>R8 - Respect everyone. Treat others as you wish to be treated with kind words and courteous behaviour.</li>
                <br></br>
                <li>R9 - Do not attack or participate in attacks on members of the community or the community itself. This will result in an immediate & permanent ban.</li>
            </ul>
            
        <h2>Donations & Account Rules</h2>
            <ul style = {styles.rulesList}>
                <li>A1 - It is your account and therefore your responsibility. Use it wisely or face punishment in line with the PBS outlined above.</li>
                <br></br>
                <li>A2 - RWT is strictly forbidden within this community, this includes trading in game items for money or purchasing accounts/characters for real world money.</li>
                <br></br>
                <li>A3 - If you chargeback an item purchased from our webstore including queue priority without speaking to us via a ticket first you will be permanently banned from MXRP.</li>
                <br></br>
                <li>A4 - The selling or trading of items purchased within our webstore are for the purchaser only, they are not to be traded or resold regardless of use case.</li>
                <br></br>
                <li>A5 - Refunds will not be issued to any member that has been banned after purchasing an item from our store. It is your responsibility to abide by our rules.</li>
            </ul>

        <h2>In Character Rules</h2>
            <ul style = {styles.rulesList}>
                <li>IC1 - Members that have characters within DOC, DOJ, Police or SAMS are strictly prohibited from partaking or engaging in Corruption RP.</li>
                <br></br>
                <li>IC2 - Separate your characters, ensure that there is a clear difference between them, both their looks and their personality, failure to do so may cause issues.</li>
                <br></br>
                <li>IC3 - Character names must not be names of famous individuals in the real world and must be sensibly chosen.</li>
                <br></br>
                <li>IC4 - If a character is in possession of the "bodycam" or "camera" item the footage obtained can legally be used in the justice system.</li>
                <br></br>
                <li>IC5 - Properties, vehicles & turfs may be removed at any given point from players or groups if the staff team deem their standards to have dropped below what is expected of them.</li>
            </ul>

        <h2>Cheating & Exploiting Rules</h2>
            <ul style = {styles.rulesList}>
                <li>E1 - The use of game modifications or enhancements that provide other players at a disadvantage will result in a permanent ban.</li>
                <br></br>
                <li>E2 - Utilising Macros or other third party software in order to gain an advantage over others will result in a permanent ban.</li>
                <br></br>
                <li>E3 - Third Party crosshairs are prohibited as well and using them will result in a permanent ban.</li>
                <br></br>
                <li>E4 - Utilising any form of exploit knowingly will result in a permanent ban.</li>
            </ul>

        <h2>Communication Rules</h2>
            <ul style = {styles.rulesList}>
                <li>C1 - You must communicate in English at all times including over text messages and/or VOIP.</li>
                <br></br>
                <li>C2 - Advertising in a malicious manner or to poach members will result in a permanent ban.</li>
                <br></br>
                <li>C3 - Refrain from heavily spamming commands, messages or other forms of communication, failure to do so will result in punishment in line with PBS.</li>
                <br></br>
                <li>C4 - Using any OOC language or communication is strictly forbidden, any threats made must be towards characters and not their players.</li>
                <br></br>
                <li>C5 - Excessive screaming, mic spamming, shouting or other forms of malicious communication is prohibited. Roleplay takes priority.</li>
                <br></br>
                <li>C6 - Engaging in offensive or disruptive behaviour towards our staff team will result in punishment either in game or via Discord in line with PBS.</li>
                <br></br>
                <li>C7 - If you have any issues with other players utilise our ticket system in order to discuss and alert the staff team to any issues that have arisen.</li>
                <br></br>
                <li>C8 - Honesty. Dishonesty is likely to result in disciplinary action, especially if you directly lie to the staff team.</li>
            </ul>
            <h4>Final Notes</h4>
            <ul style = {styles.rulesList}>
                <li>PBS = Player Ban System</li>
                <li>RWT = Real World Trade/Trading</li>
            </ul>
        </div>
    </main>
  );
};

const styles = {
  main: {
    padding: "20px",
    textAlign: "center",
  },
  rulesList: {
    listStyle: "none",
    padding: 0,
    textAlign: "left",
    maxWidth: "75%",
    margin: "20px auto",
    lineHeight: "1.6",
  },
};

export default Rules;