import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ApplicationReview = () => {
  const [applications, setApplications] = useState([]);
  const [filter, setFilter] = useState("Pending"); // Default to Pending applications
  const [selectedApplication, setSelectedApplication] = useState(null); // For detailed view
  const navigate = useNavigate();

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const response = await axios.get("https://al.maximusrp.com:5000/api/applications", {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        setApplications(response.data);
      } catch (error) {
        console.error("Error fetching applications:", error);
      }
    };

    fetchApplications();
  }, []);

  const handleStatusChange = async (applicationId, newStatus) => {
    try {
      await axios.put(
        `https://al.maximusrp.com:5000/api/applications/${applicationId}`,
        { status: newStatus },
        { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
      );
      setApplications((prev) =>
        prev.map((app) =>
          app.id === applicationId ? { ...app, status: newStatus } : app
        )
      );
      setSelectedApplication(null); // Close details view after action
    } catch (error) {
      console.error("Error updating application status:", error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  const filteredApplications = applications.filter(
    (app) => app.status === filter
  );

  const styles = {
    container: {
      maxWidth: "800px",
      margin: "0 auto",
      padding: "20px",
      backgroundColor: "#4D4C4C", // Dark background
      borderRadius: "8px",
      color: "#ddd", // Light text for contrast
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    },
    subHeader: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "20px",
    },
    button: {
      padding: "10px 15px",
      margin: "0 5px",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      color: "#fff",
    },
    approveButton: {
      backgroundColor: "#28a745", // Green
    },
    denyButton: {
      backgroundColor: "#dc3545", // Red
    },
    backButton: {
      backgroundColor: "#007bff", // Blue
    },
    moveToPendingButton: {
      backgroundColor: "#ffc107", // Yellow for Pending
    },
    logoutButton: {
      backgroundColor: "#6c757d", // Grey
      marginLeft: "auto",
    },
    table: {
      width: "100%",
      borderCollapse: "collapse",
      color: "#ddd",
    },
    th: {
      backgroundColor: "#4D4C4C", // Darker header
      color: "#fff",
      padding: "10px",
      textAlign: "left",
    },
    td: {
      padding: "10px",
      borderBottom: "1px solid #555", // Subtle separation
    },
    details: {
      backgroundColor: "#4D4C4C", // Even darker background for details view
      padding: "15px",
      borderRadius: "8px",
      marginBottom: "20px",
    },
  };

  return (
    <div style={styles.container}>
      <h1>Application Review</h1>
      <div style={styles.subHeader}>
        <button
          style={{ ...styles.button, ...styles.logoutButton }}
          onClick={handleLogout}
        >
          Logout
        </button>
        <button
          style={{ ...styles.button, backgroundColor: filter === "Pending" ? "#0056b3" : "#007bff" }}
          onClick={() => setFilter("Pending")}
        >
          Pending
        </button>
        <button
          style={{ ...styles.button, backgroundColor: filter === "Approved" ? "#0056b3" : "#007bff" }}
          onClick={() => setFilter("Approved")}
        >
          Approved
        </button>
        <button
          style={{ ...styles.button, backgroundColor: filter === "Denied" ? "#0056b3" : "#007bff" }}
          onClick={() => setFilter("Denied")}
        >
          Denied
        </button>
      </div>
      {!selectedApplication ? (
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={styles.th}>ID</th>
              <th style={styles.th}>Discord ID</th>
              <th style={styles.th}>Email</th>
              <th style={styles.th}>Status</th>
              <th style={styles.th}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredApplications.map((application) => (
              <tr key={application.id}>
                <td style={styles.td}>{application.id}</td>
                <td style={styles.td}>{application.discordId}</td>
                <td style={styles.td}>{application.email}</td>
                <td style={styles.td}>{application.status}</td>
                <td style={styles.td}>
                  <button
                    style={styles.button}
                    onClick={() => setSelectedApplication(application)}
                  >
                    Review
                  </button>
                  {(application.status === "Approved" || application.status === "Denied") && (
                    <button
                      style={styles.button}
                      onClick={() => handleStatusChange(application.id, "Pending")}
                    >
                      Move to Pending
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div style={styles.details}>
          <h2>Application Details</h2>
          <p><strong>App ID:</strong> {selectedApplication.id}</p>
          <p><strong>Age:</strong> {selectedApplication.age}</p>
          <p><strong>Discord ID:</strong> {selectedApplication.discordId}</p>
          <p><strong>Email:</strong> {selectedApplication.email}</p>
          <p><strong>Question 1:</strong> {selectedApplication.question1}</p>
          <p><strong>Question 2:</strong> {selectedApplication.question2}</p>
          <p><strong>Question 3:</strong> {selectedApplication.question3}</p>
          <p><strong>Question 4:</strong> {selectedApplication.question4}</p>
          <p><strong>Question 5:</strong> {selectedApplication.question5}</p>
          <p><strong>Question 6:</strong> {selectedApplication.question6}</p>
          <p><strong>Question 7:</strong> {selectedApplication.question7}</p>
          <p><strong>Question 8:</strong> {selectedApplication.question8}</p>
          <button
            style={{ ...styles.button, ...styles.approveButton }}
            onClick={() => handleStatusChange(selectedApplication.id, "Approved")}
          >
            Approve
          </button>
          <button
            style={{ ...styles.button, ...styles.denyButton }}
            onClick={() => handleStatusChange(selectedApplication.id, "Denied")}
          >
            Deny
          </button>
          {/* Move to Pending button should only appear if the application is not already Pending */}
          {(selectedApplication.status === "Approved" || selectedApplication.status === "Denied") && (
            <button
              style={{ ...styles.button, ...styles.moveToPendingButton }}
              onClick={() => handleStatusChange(selectedApplication.id, "Pending")}
            >
              Move to Pending
            </button>
          )}
          <button
            style={{ ...styles.button, ...styles.backButton }}
            onClick={() => setSelectedApplication(null)}
          >
            Back
          </button>
        </div>
      )}
    </div>
  );
};

export default ApplicationReview;