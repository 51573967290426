import React from "react";

const Home = () => {
  return (
    <main style={styles.main}>
      <h2>Welcome to your new home.</h2>
      <p>Explore our website and become part of our growing community.</p>
    </main>
  );
};

const styles = {
  main: {
    padding: "20px",
    textAlign: "center",
  },
};

export default Home;