import React from "react";

const Staff = () => {
  const staffMembers = [
    { name: "Richy", role: "Project Lead", image: "https://i.gyazo.com/acf18ee26d9b15d5000c3658080695b5.png" },
    { name: "Jinx", role: "Helper", image: "https://i.gyazo.com/70204a1d78510f6349667fa870518d22.jpg" },
  ];

  return (
    <main style={styles.main}>
      <h2 style={styles.heading}>Our Staff Team</h2>
      <div style={styles.grid}>
        {staffMembers.map((member, index) => (
          <div key={index} style={styles.card}>
            <img src={member.image} alt={`${member.name}'s profile`} style={styles.image} />
            <h3 style={styles.name}>{member.name}</h3>
            <p style={styles.role}>{member.role}</p>
          </div>
        ))}
      </div>
    </main>
  );
};

const styles = {
  main: {
    padding: "20px",
    textAlign: "center",
  },
  heading: {
    fontSize: "2rem",
    marginBottom: "20px",
  },
  grid: {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
    justifyContent: "center",
  },
  card: {
    background: "#4b4b4b",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    padding: "20px",
    width: "200px",
    textAlign: "center",
  },
  image: {
    borderRadius: "50%",
    width: "100px",
    height: "100px",
    objectFit: "cover",
    marginBottom: "15px",
  },
  name: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    margin: "10px 0 5px",
  },
  role: {
    color: "Green",
  },
};

export default Staff;